<template>
  <div class="mx-4 mt-4">
    <v-card class="elevation-5">
      <v-card-title>
        {{ $t('Vehicles') }}
        <v-spacer></v-spacer>

        <!--                <v-btn-->
        <!--                    @click="-->
        <!--            getDetailExcel(1); inventory_excel = [];"-->
        <!--                    style="background-color:blue; color: white; margin-right: 10px"-->
        <!--                    class="ml-8"-->
        <!--                >-->
        <!--                    Ecelga yuklash-->
        <!--                </v-btn>-->


        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            clearable
            @keyup.native.enter="getApplicationList"
            outlined
            dense
        ></v-text-field>

      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="vehicles"
          :height="screenHeight"
          class="elevation-1 ma-1"
          :search="search"
          style="border: 1px solid #aaa"
          fixed-header
          single-expand
          :options.sync="dataTableOptions"
          item-key="id"
          :server-items-length="server_items_length"
          :disable-pagination="true"
          :footer-props="{
          itemsPerPageText: 'Sahifadagi yozuvlar soni',
          itemsPerPageOptions: [50, 100, 200],
          showFirstLastPage: true,
        }"
          @update:page="updatePage"
          @update:items-per-page="updatePerPage"
      >
        <template v-slot:item.id="{ item }">{{
            vehicles.map((v) => v.id).indexOf(item.id) + from
          }}
        </template>

        <template v-slot:item.options="{ item }"
        >
          <v-icon color="primary" @click="editVehicle(item)"
          >mdi-pencil sa
          </v-icon
          >
          <v-icon color="error" @click="deleteVehicle(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
      <v-dialog v-model="downloadExcel" hide-overlay persistent width="300">
        <v-card>
          <v-card-text class="py-1 px-3">
            <v-btn
                color="success"
                class="mx-10"
                @click="downloadExcel = false"
                text
            >
              <download-excel
                  :data="inventory_excel"
                  :name="'404 Atomobillar_ruyxati.xls'"
              >
                <span style="color: #4caf50">{{ $t("download") }}</span>
                <v-icon color="success" height="20">mdi-download</v-icon>
              </download-excel>
            </v-btn>
            <v-btn class color="error" @click="downloadExcel = false" icon>
              <v-icon color="error" height="20">mdi-close</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="VehicleModal"
          persistent
          max-width="50%"
          @keydown.esc="UserModal = false"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('Edit Car') }}</span>
            <v-spacer></v-spacer>
            <v-btn color="red" x-small fab class @click="VehicleModal = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col class="pt-0" cols="12">
                  <label>{{ $t('Tabno') }}</label>
                  <v-text-field v-model="form.ga_seq" dense></v-text-field>
                </v-col>
                <v-col class="pt-0" cols="12">
                  <label>{{ $t('Status') }}</label>
                  <v-text-field v-model="form.status" dense></v-text-field>
                </v-col>
                <v-col class="pt-0" cols="12">
                  <label>{{ $t('Sector') }}</label>
                  <v-text-field v-model="form.sector" dense></v-text-field>
                </v-col>
                <v-col class="pt-0" cols="12">
                  <label>{{ $t('Row') }}</label>
                  <v-text-field v-model="form.row" dense></v-text-field>
                </v-col>
                <v-col class="pt-0" cols="12">
                  <label>{{ $t('Tcd_date') }}</label>
                  <v-text-field v-model="form.tcd_date" dense></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="green" dark @click="save">{{ $t('save') }}</v-btn>
            <!--                        <v-btn color="red darken-1" dark @click="onClickOutside">{{ $t('close') }}</v-btn>-->
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="loading" width="300" hide-overlay>
        <v-card color="primary" dark>
          <v-card-text>
            {{ $t('loadingText') }}
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>


let axios = require("axios").default;
export default {
  data() {
    return {
      loading: false,
      vehicles: [],
      search: '',
      VehicleModal: false,
      form: {},
      inventory_excel: [],
      dataTableOptions: {
        page: 1,
        itemsPerPage: 50,
      },
      page: 1,
      server_items_length: -1,
      from: 0,
      downloadExcel: false,
    };
  },
  computed: {
    headers() {
      return [
        {text: this.$t('ID'), value: 'id', sortable: false},
        {text: this.$t('FIO'), value: 'full_name', sortable: false},
        {text: this.$t('Pinfl'), value: 'pinfl', sortable: false},
        {text: this.$t('Contract Number'), value: 'contract_code', sortable: false},
        {text: this.$t('Vin Number'), value: 'vin_code', sortable: false},
        {text: this.$t('Diler Name'), value: 'dealer_code', sortable: false},
        {text: this.$t('Paid sum'), value: 'paid', sortable: false},
        {text: this.$t('Overpaid'), value: 'overpaid', sortable: false},
        {text: this.$t('Price'), value: 'price', sortable: false},
        {text: this.$t('Model'), value: 'model_no', sortable: false},
        {text: this.$t('Contract Date'), value: 'contract_date', sortable: false},
        {
          text: 'Amallar',
          align: 'center',
          value: 'options',
          sortable: false,
          width: 80,
        },
      ];
    },
    screenHeight() {
      return window.innerHeight - 210;
    },
  },
  methods: {
    getApplicationList() {
      this.loading = true;
      this.$axios
          .post(this.$store.state.backend_url + '/api/getContracts', {
            pagination: this.dataTableOptions,
            search: this.search,
          })
          .then((res) => {
            this.server_items_length = res.data.total;
            this.from = res.data.from;
            this.vehicles = res.data.data;
            this.loading = false;
          })
          .catch(function (error) {
            console.log(error);
            this.loading = false;
          });
    },



    updatePage() {
      this.getApplicationList();
    },
    screenWidth() {
      return window.innerWidth;
    },
    updatePerPage() {
      this.getApplicationList();
    },

    getDetailExcel(page) {
      let new_array = [];
      this.loading = true;
      axios
          .post(this.$store.state.backend_url + "/api/applications/get-excel", {
            filter: this.filter,
            type: 1,
            pagination: {
              page: page,
              itemsPerPage: 1000,
            },
          })
          .then((response) => {
            response.data.map((v, index) => {
              new_array.push({
                "№": index + page,
                Vin: v.Vin,
                Tabno: v.Tabno,
                Status: v.Status,
                Sector: v.Sector,
                Row: v.Row,
                Tcd_date: v.Tcd_date,
              });
            });
            this.inventory_excel = this.inventory_excel.concat(new_array);
            if (response.data.length == 1000) {
              this.getDetailExcel(++page);
            } else {
              this.loading = false;
              this.downloadExcel = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
  },
  mounted() {
    this.getApplicationList();
    document.title = this.$t('drawings');
  },
};
</script>
<style>
.itemWidth {
  width: 15%;
}

.dialogHeight {
  max-height: 80%;
}
</style>
